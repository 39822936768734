import React, { useEffect, useState } from 'react';
import { Calendar30DaysIcon, CloseIcon } from 'components/mui';
import { Dialog, DialogContent, DialogTitle, Stack, Tooltip, Box, Typography, IconButton, DialogActions, Divider, Button, Drawer } from '@mui/material';
import { EndJourneyCheck } from './end-journey-check.component';
import { TimeTriggerCheck } from './time-trigger-check.component';
import { DuplicateSegmentCheck } from './duplicate-segment-check.component';
export const RecommendationsCheck = props => {
  useEffect(() => {
    console.log('RecommendationsCheck props', props);
  }, [props]);
  return <Stack sx={{
    px: '10px'
  }} spacing={2}>
      <Stack spacing={2}>
        {props.endJourneyNodes && props.endJourneyNodes.some(n => n.showRecommendation) && <EndJourneyCheck endJourneyNodes={props?.endJourneyNodes} scrollToNode={props.scrollToNode} ignoreRecommendation={props.ignoreRecommendation} />}

        {props.timeTriggerNodes && props.timeTriggerNodes.some(n => n.showRecommendation) && <TimeTriggerCheck timeTriggerNodes={props?.timeTriggerNodes} scrollToNode={props.scrollToNode} ignoreRecommendation={props.ignoreRecommendation} />}

        {props.duplicateSegmentNodes && props.duplicateSegmentNodes.some(n => n.showRecommendation) && <DuplicateSegmentCheck duplicateSegmentNodes={props?.duplicateSegmentNodes} scrollToNode={props.scrollToNode} ignoreRecommendation={props.ignoreRecommendation} />}
        {!(props.endJourneyNodes && props.endJourneyNodes.some(n => n.showRecommendation)) && !(props.timeTriggerNodes && props.timeTriggerNodes.some(n => n.showRecommendation)) && !(props.duplicateSegmentNodes && props.duplicateSegmentNodes.some(n => n.showRecommendation)) && <Box>
              <Typography sx={{
          fontWeight: '600',
          fontSize: '16px'
        }}>No More Recommendations</Typography>
            </Box>}

        {(props.endJourneyNodes?.some(n => !n.showRecommendation) || props.timeTriggerNodes?.some(n => !n.showRecommendation) || props.duplicateSegmentNodes?.some(n => !n.showRecommendation)) && <Box>
            <Button onClick={e => {
          e.stopPropagation();
          props.restoreIgnoredSuggestions();
        }} sx={{
          textTransform: 'capitalize'
        }}>
              Restore ignored suggestions
            </Button>
          </Box>}
      </Stack>
    </Stack>;
};