import React, { useState, useContext, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { getSegments, getCheckFieldNodeMetadata } from 'shared/common.api';
import { UserStateContext } from 'context/user-state-context';
import { Autocomplete, Box, FormControl, InputAdornment, Paper, Stack, TextField, Tooltip, Typography, Grid, InputLabel, Select, MenuItem } from '@mui/material';
import { CustomerIcon, TransactionIcon, VehicleIcon, ShoppingCartIcon, Calendar30DaysIcon } from 'components/mui';
export const ActionRemoveFromSegment = ({
  data,
  node,
  readOnly,
  resize,
  update
}) => {
  const {
    asCompany
  } = useContext(UserStateContext);

  // loading states
  const [loading, setLoading] = useState(true);

  // segment states
  const [cachedSegmentList, setCachedSegmentList] = useState([]);
  const [shownSegmentList, setShownSegmentList] = useState([]); // what we actually show to the user
  const [selectedSegment, setSelectedSegment] = useState();

  // session states
  const [nodeMetaData, setNodeMetaData] = useState();
  const [sessionTypes, setSessionTypes] = useState([]);
  const [selectedSessionType, setSelectedSessionType] = useState('None');
  useEffect(() => {
    const retrieveNodeMetadata = async () => {
      if (nodeMetaData) {
        return nodeMetaData;
      }
      const response = await getCheckFieldNodeMetadata(node.id);
      setNodeMetaData(response.data);
      return response.data;
    };
    const initialize = async () => {
      const currentSegmentList = await retrieveAvailableSegments();
      setCachedSegmentList(currentSegmentList);
      setSelectedSegment(currentSegmentList.find(existingSegment => existingSegment.id === data.segment_id));
      const nodeMetaData = await retrieveNodeMetadata();
      const metaSessionTypes = Object.keys(nodeMetaData.session.types);
      const cleanedTypes = metaSessionTypes.filter(types => !types.includes('customer'));
      setSessionTypes(cleanedTypes);
      let currentSessionType = data?.session?.type ? data.session.type : 'None';
      if (currentSessionType === null || currentSessionType === undefined) {
        currentSessionType = 'None';
      }
      if (currentSessionType && currentSessionType !== 'None') {
        setShownSegmentList(currentSegmentList.filter(segment => segment.included === currentSessionType));
      } else {
        setShownSegmentList(currentSegmentList.filter(segment => segment.included === null));
      }
      setSelectedSessionType(currentSessionType);
      setLoading(false);
    };
    initialize();
  }, []);
  const retrieveAvailableSegments = async () => {
    const currentSegments = await getSegments({
      company: asCompany.id,
      time_dynamic_segment: false,
      limit: 600
    });
    return currentSegments.data.results;
  };
  const makeOverflowTooltip = (content, length, bold) => {
    const contentLength = content?.length || content?.props?.children?.length;
    if (content) {
      return <Tooltip title={contentLength > length ? <> {content} </> : ''}>
          <Typography variant={bold ? 'tableHeader' : ''}>
            {contentLength > length ? <>{('' + (content.props?.children || content)).slice(0, length) + '...'}</> : <>{content}</>}
          </Typography>
        </Tooltip>;
    }
    return <Typography variant="nullData" color={'#BEBEBE'}>
        <span>--</span>{' '}
      </Typography>;
  };

  // TODO do we have icons for reviews or surveys?
  const getIcon = included => {
    if (included === 'possession') {
      return <VehicleIcon />;
    } else if (included === 'transaction') {
      return <TransactionIcon />;
    } else if (included === 'schedule') {
      return <Calendar30DaysIcon />;
    } else if (included === 'cart') {
      return <ShoppingCartIcon />;
    } else {
      return <CustomerIcon />;
    }
  };
  const updateNodeParameters = (newSegmentId, newSessionType) => {
    const segment_id = newSegmentId ? newSegmentId : null;
    const session = newSessionType ? {
      type: newSessionType
    } : null;
    update({
      segment_id,
      session
    });
  };
  const handleSessionTypeChange = async event => {
    let newSessionType = event.target.value;
    setSelectedSessionType(newSessionType);
    if (newSessionType === 'None') {
      setShownSegmentList(cachedSegmentList.filter(segment => segment.included === null));
      updateNodeParameters(selectedSegment ? selectedSegment.id : null, null);
      const persistingSegment = selectedSegment ? selectedSegment.included === null : null;
      if (!persistingSegment) {
        setSelectedSegment();
      }
    } else {
      setShownSegmentList(cachedSegmentList.filter(segment => segment.included === newSessionType));
      updateNodeParameters(selectedSegment ? selectedSegment.id : null, newSessionType);
      // Only if the current segment matches the session type do we persist it as "chosen"
      const persistingSegment = selectedSegment ? selectedSegment.included === newSessionType : undefined;
      if (!persistingSegment) {
        setSelectedSegment();
      }
    }
  };
  const friendlySessionTypeName = sessionType => {
    if (sessionType === 'customer') {
      return 'Customer';
    } else if (sessionType === 'cart') {
      return 'Cart';
    } else if (sessionType === 'review') {
      return 'Review';
    } else if (sessionType === 'schedule') {
      return 'Schedule';
    } else if (sessionType === 'transaction') {
      return 'Transaction';
    } else if (sessionType === 'survey_invite') {
      return 'Survey Invite';
    } else if (sessionType === 'possession') {
      return 'Vehicle';
    } else {
      return 'None';
    }
  };
  return <Grid>
      <Grid className="form-group">
        <Paper sx={{
        width: '103px',
        height: '19px',
        pl: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'absolute',
        marginTop: '-16px',
        zIndex: 1,
        boxShadow: 'none'
      }}>
          <Typography sx={{
          position: 'absolute',
          marginTop: '10px',
          opacity: 0.5,
          '&.Mui-focused': {
            opacity: 1
          },
          fontSize: '12px'
        }} id="static-segment-select-label">
            Static Segment
          </Typography>
        </Paper>
        <FormControl variant="outlined" sx={{
        width: '315px'
      }}>
          <Autocomplete disabled={loading} id="choose-static-segment-select" options={shownSegmentList} getOptionLabel={option => option.name} value={selectedSegment || null} onChange={(event, newValue) => {
          if (!newValue) {
            updateNodeParameters(null, null);
            return;
          }
          setSelectedSegment(newValue);
          const foundSegment = shownSegmentList.find(segment => segment.id === newValue.id);
          updateNodeParameters(foundSegment.id, selectedSessionType);
        }} filterOptions={(options, {
          inputValue
        }) => {
          return options.filter(option => option.name.toLowerCase().includes(inputValue.toLowerCase()));
        }} renderInput={params => {
          return <TextField {...params} label="" placeholder={selectedSegment ? `${selectedSegment.name}` : 'Choose Static Segment'} InputProps={{
            ...params.InputProps,
            startAdornment: <InputAdornment position="start">{getIcon(selectedSegment?.included)}</InputAdornment>
          }} value={selectedSegment ? selectedSegment.name : ''} />;
        }} renderOption={(props, option, {
          index
        }) => {
          return <Box component="li" {...props} key={option.name + index} sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px'
          }}>
                  {getIcon(option.included)}
                  {makeOverflowTooltip(option.name, 30)}
                </Box>;
        }} />
        </FormControl>

        {sessionTypes.length > 0 && <Grid sx={{
        marginTop: '1rem'
      }}>
            <FormControl variant="outlined" sx={{
          minWidth: '300px'
        }}>
              <InputLabel>Session Type</InputLabel>
              <Select value={selectedSessionType} label="Choose Session Type" onChange={async event => {
            await handleSessionTypeChange(event);
          }} disabled={loading}>
                {/* This is a default / placeholder for '' so it's a bit more straight forward*/}
                <MenuItem key="None" value={'None'}>
                  None
                </MenuItem>
                {sessionTypes.map(type => {
              return <MenuItem key={type} value={type}>
                      {friendlySessionTypeName(type)}
                    </MenuItem>;
            })}
              </Select>
            </FormControl>
          </Grid>}

        <Stack direction={'column'} spacing={2} sx={{
        pb: '25px',
        pt: '10px',
        opacity: 0.7
      }}>
          <Typography>{'Segment Type: Static'}</Typography>

          <Typography>
            {'Segment Inclusion: '}
            {friendlySessionTypeName(selectedSegment ? selectedSegment.included : null)}
          </Typography>
        </Stack>
      </Grid>
    </Grid>;
};