import React, { useState, useEffect, useContext, Component } from 'react';
import { cloneDeep } from 'lodash';
import { getCheckFieldNodeMetadata, getSegments, newSegment } from 'shared/common.api';
import { UserStateContext } from 'context/user-state-context';
// import { Button } from 'components/button/button.component';
import { Loader } from 'components/loader/loader.component';
import { Stack, Typography, Autocomplete, Box, FormControl, InputAdornment, Paper, TextField, Tooltip, Grid, InputLabel, Select, MenuItem, Divider, Button } from '@mui/material';
import { CustomerIcon, TransactionIcon, VehicleIcon, ShoppingCartIcon, Calendar30DaysIcon } from 'components/mui';
const Styles = {
  textHelper: {
    textTransform: 'none',
    padding: '8px',
    fontWeight: '400'
  }
};
export const ActionAddToSegment = ({
  data,
  node,
  readOnly,
  resize,
  update
}) => {
  const {
    asCompany
  } = useContext(UserStateContext);

  // loading stuff
  const [loadingCreateSegment, setLoadingCreateSegment] = useState(false);
  const [loading, setLoading] = useState(true);

  // segment selection states
  const [cachedSegmentList, setCachedSegmentList] = useState([]);
  const [shownSegmentList, setShownSegmentList] = useState([]); // what we actually show to the user
  const [newSegmentPreSave, setNewSegmentPreSave] = useState({
    name: '',
    description: '',
    included: null
  });
  const [isCreateNewSegment, setIsCreateNewSegment] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState();

  // session related states
  const [nodeMetaData, setNodeMetaData] = useState();
  const [sessionTypes, setSessionTypes] = useState([]);
  const [selectedSessionType, setSelectedSessionType] = useState('None');
  useEffect(() => {
    const retrieveNodeMetadata = async () => {
      if (nodeMetaData) {
        return nodeMetaData;
      }
      const response = await getCheckFieldNodeMetadata(node.id);
      setNodeMetaData(response.data);
      return response.data;
    };
    const intitialize = async () => {
      // Initialize first segment list / cache data to prevent requerying
      const currentSegmentList = await retrieveAvailableSegments();
      setCachedSegmentList(cloneDeep(currentSegmentList));
      setSelectedSegment(currentSegmentList.find(existingSegment => existingSegment.id === data.segment_id));
      const nodeMetaData = await retrieveNodeMetadata();
      const metaSessionTypes = Object.keys(nodeMetaData.session.types);
      const cleanedTypes = metaSessionTypes.filter(types => !types.includes('customer'));
      setSessionTypes(cleanedTypes);
      let currentSessionType = data?.session?.type ? data.session.type : 'None';
      if (currentSessionType === null || currentSessionType === undefined) {
        currentSessionType = 'None';
      }
      if (currentSessionType && currentSessionType !== 'None') {
        setShownSegmentList(currentSegmentList.filter(segment => segment.included === currentSessionType));
      } else {
        setShownSegmentList(currentSegmentList.filter(segment => segment.included === null));
      }
      setSelectedSessionType(currentSessionType);
      setLoading(false);
    };
    intitialize();
  }, []);
  const updateNodeParameters = (newSegmentId, newSessionType) => {
    const segment_id = newSegmentId ? newSegmentId : null;
    const session = newSessionType ? {
      type: newSessionType
    } : null;
    update({
      segment_id,
      session
    });
  };
  const onChangeNewSegment = e => {
    let currentSessionType = null;
    if (selectedSessionType !== 'None') {
      currentSessionType = selectedSessionType;
    }
    setNewSegmentPreSave({
      ...newSegmentPreSave,
      [e.target.name]: e.target.value,
      included: currentSessionType
    });
  };
  const retrieveAvailableSegments = async () => {
    const currentSegments = await getSegments({
      company: asCompany.id,
      time_dynamic_segment: false,
      limit: 600
    });
    return currentSegments.data.results;
  };
  const createNewSegment = async () => {
    setLoadingCreateSegment(true);
    try {
      const results = await newSegment({
        ...newSegmentPreSave,
        company: asCompany.id
      });
      const createdSegment = results.data;
      const newSegmentList = cloneDeep(cachedSegmentList);
      newSegmentList.push(createdSegment);
      setCachedSegmentList(newSegmentList);
      console.log(selectedSessionType, 'selectedSessionType');
      if (selectedSessionType === 'None') {
        setShownSegmentList(newSegmentList.filter(segment => segment.included === null));
      } else {
        setShownSegmentList(newSegmentList.filter(segment => segment.included === selectedSessionType));
      }
      updateNodeParameters(createdSegment.id, selectedSessionType);
      setSelectedSegment(createdSegment);
      setNewSegmentPreSave({
        name: '',
        description: '',
        included: null
      });
    } catch (error) {
      // TODO probably want some feedback at some point
      console.error(error);
    } finally {
      setIsCreateNewSegment(false);
      setLoadingCreateSegment(false);
    }
  };
  const isNewSegmentValid = () => {
    if (newSegmentPreSave.name.length === 0) {
      return true;
    }
    return false;
  };
  const makeOverflowTooltip = (content, length, bold) => {
    const contentLength = content?.length || content?.props?.children?.length;
    if (content) {
      return <Tooltip title={contentLength > length ? <> {content} </> : ''}>
          <Typography variant={bold ? 'tableHeader' : ''}>
            {contentLength > length ? <>{('' + (content.props?.children || content)).slice(0, length) + '...'}</> : <>{content}</>}
          </Typography>
        </Tooltip>;
    }
    return <Typography variant="nullData" color={'#BEBEBE'}>
        <span>--</span>{' '}
      </Typography>;
  };
  const getIcon = included => {
    if (included === 'possession') {
      return <VehicleIcon />;
    } else if (included === 'transaction') {
      return <TransactionIcon />;
    } else if (included === 'schedule') {
      return <Calendar30DaysIcon />;
    } else if (included === 'cart') {
      return <ShoppingCartIcon />;
    } else {
      return <CustomerIcon />;
    }
  };
  const handleSessionTypeChange = async event => {
    let newSessionType = event.target.value;
    setSelectedSessionType(newSessionType);
    if (newSessionType === 'None') {
      setShownSegmentList(cachedSegmentList.filter(segment => segment.included === null));
      updateNodeParameters(selectedSegment ? selectedSegment.id : null, null);
      const persistingSegment = selectedSegment ? selectedSegment.included === null : null;
      if (!persistingSegment) {
        setSelectedSegment();
      }
    } else {
      setShownSegmentList(cachedSegmentList.filter(segment => segment.included === newSessionType));
      updateNodeParameters(selectedSegment ? selectedSegment.id : null, newSessionType);
      // Only if the current segment matches the session type do we persist it as "chosen"
      const persistingSegment = selectedSegment ? selectedSegment.included === newSessionType : undefined;
      if (!persistingSegment) {
        setSelectedSegment();
      }
    }
  };
  const friendlySessionTypeName = sessionType => {
    if (sessionType === 'customer') {
      return 'Customer';
    } else if (sessionType === 'cart') {
      return 'Cart';
    } else if (sessionType === 'review') {
      return 'Review';
    } else if (sessionType === 'schedule') {
      return 'Schedule';
    } else if (sessionType === 'transaction') {
      return 'Transaction';
    } else if (sessionType === 'survey_invite') {
      return 'Survey Invite';
    } else if (sessionType === 'possession') {
      return 'Vehicle';
    } else {
      return 'None';
    }
  };
  return <Grid>
      <Grid className="form-group" style={{
      marginBottom: isCreateNewSegment ? '1rem' : '-9px'
    }}>
        <Paper sx={{
        width: '103px',
        height: '19px',
        pl: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'absolute',
        marginTop: '-16px',
        zIndex: 1,
        boxShadow: 'none'
      }}>
          <Typography sx={{
          position: 'absolute',
          marginTop: '10px',
          opacity: 0.5,
          '&.Mui-focused': {
            opacity: 1
          },
          fontSize: '12px'
        }} id="static-segment-select-label">
            Static Segment
          </Typography>
        </Paper>
        <FormControl variant="outlined" sx={{
        width: '315px'
      }}>
          <Autocomplete disabled={loading} id="choose-static-segment-select" options={shownSegmentList} getOptionLabel={option => {
          return option.name ? option.name : '';
        }} value={selectedSegment || null} onChange={(event, newValue) => {
          if (!newValue) {
            updateNodeParameters(null, null);
            return;
          }
          setSelectedSegment(newValue);
          const foundSegment = shownSegmentList.find(segment => segment.id === newValue.id);
          updateNodeParameters(foundSegment.id, selectedSessionType);
        }} filterOptions={(options, {
          inputValue
        }) => {
          return options.filter(option => option.name.toLowerCase().includes(inputValue.toLowerCase()));
        }} renderInput={params => <TextField disabled={loading} {...params} label="" placeholder={selectedSegment ? `${selectedSegment.name}` : 'Choose Static Segment'} InputProps={{
          ...params.InputProps,
          startAdornment: <InputAdornment position="start">{getIcon(selectedSegment?.included)}</InputAdornment>
        }} value={selectedSegment ? selectedSegment.name : ''} />} renderOption={(props, option, {
          index
        }) => <Box component="li" {...props} key={option.name + index} sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px'
        }}>
                {getIcon(option.included)}
                {makeOverflowTooltip(option.name, 30)}
              </Box>} />
        </FormControl>
        {sessionTypes.length > 0 && <Grid sx={{
        marginTop: '1rem'
      }}>
            <FormControl variant="outlined" sx={{
          minWidth: '300px'
        }}>
              <InputLabel>Session Type</InputLabel>
              <Select value={selectedSessionType} label="Choose Session Type" onChange={async event => {
            await handleSessionTypeChange(event);
          }} disabled={loading}>
                {/* This is a default / placeholder for '' so it's a bit more straight forward*/}
                <MenuItem key="None" value={'None'}>
                  None
                </MenuItem>
                {sessionTypes.map(type => {
              return <MenuItem key={type} value={type}>
                      {friendlySessionTypeName(type)}
                    </MenuItem>;
            })}
              </Select>
            </FormControl>
          </Grid>}
        <Grid spacing={2} sx={{
        pb: '2rem',
        pt: '10px',
        opacity: 0.7
      }}>
          <Typography>{'Segment Type: Static'}</Typography>
          <Typography>
            {'Segment Inclusion: '}
            {friendlySessionTypeName(selectedSegment ? selectedSegment.included : null)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        {isCreateNewSegment ? <Grid>
            <Divider />
            <Grid sx={{
          mt: '1rem'
        }}>
              <Grid sx={{
            mt: '1rem'
          }}>
                <Typography>New Static Segment</Typography>
                <TextField sx={{
              mt: '1rem'
            }} type="text" className="form-control mb-2" name="name" value={newSegmentPreSave.name || ''} placeholder="Segment Name" onChange={e => onChangeNewSegment(e)} size="small" width="100%" />
              </Grid>
              <Grid sx={{
            mt: '.5rem'
          }}>
                {/* TODO because journey panel / windows are not 
                 flexible we can't use a MUI textarea*/}
                <TextField cols="3" name="description" className="form-control " placeholder="Segment Description" onChange={e => onChangeNewSegment(e)} value={newSegmentPreSave.description || ''} size="small" width="100%" />
              </Grid>
              <Grid sx={{
            mt: '2rem'
          }}>
                <Button variant="contained" onClick={async () => {
              await createNewSegment();
            }} disabled={loadingCreateSegment || isNewSegmentValid()}>
                  {loadingCreateSegment ? <Loader size="sm" /> : 'Create New Segment'}
                </Button>
              </Grid>
            </Grid>
          </Grid> : <Grid container>
            <Typography disabled={loading} component="label" style={{
          textTransform: 'none',
          cursor: 'pointer',
          color: '#53A6D6',
          fontSize: '14px'
        }} onClick={() => {
          setIsCreateNewSegment(true);
        }}>
              Create New Static Segment
            </Typography>
          </Grid>}
      </Grid>
    </Grid>;
};