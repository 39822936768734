import React, { useState, useContext, useEffect } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { Emoji } from 'components/emoji/emoji.component';
import { SmsTemplatesMenu } from 'components/templates/sms-templates.component';
import { Stack } from '@mui/material';
import { ModalDialog } from 'components';

/**
 * TODO There are some hard coded node specific things in here. We should probably fix that.
 * TODO A lot of this is shared with the SMSMessage component
 */

export const ReviewMessage = props => {
  const {
    companyFields
  } = useContext(UserStateContext);
  const {
    param
  } = props;
  const message = props.data.message || '';
  const [messageHeight, setMessageHeight] = useState(50);
  const [showAttemptedAttachementModal, setShowAttemptedAttachementModal] = useState(false);
  const onMessageChange = e => {
    setMessageHeight(e.target.scrollHeight);
    props.update({
      message: e.target.value
    });
  };
  const insertEmoji = e => {
    props.update({
      message: props.data.message ? props.data.message + e : e
    });
  };
  const insertData = e => {
    const {
      message
    } = props.data;
    props.update({
      message: `${message || ''}{{{${e.target.value}}}}`
    });
  };
  const getId = (groupId, fieldId) => {
    return `${groupId}.${fieldId?.replace(/__/g, '.')}`;
  };
  const handleTemplateChoice = (template, revision) => {
    props.update({
      message: props.data.message ? props.data.message + revision.message : revision.message
    });
    if (revision.media.length > 0) {
      setShowAttemptedAttachementModal(true);
    }
  };
  return <>
      <>
        <div className="form-group">
          <div className="d-flex justify-content-between align-items-center">
            <label className="my-0">{param.label}</label>
            <Stack direction="row" spacing={1}>
              <SmsTemplatesMenu handleTemplateChoice={handleTemplateChoice} placement="top-start" />
              <Emoji openDirection="right" onEmojiClick={e => insertEmoji(e)} />
            </Stack>
          </div>
          <div className="form-control" style={{
          height: 'auto',
          padding: '0px'
        }}>
            <textarea cols="3" className="form-control" style={{
            // maxHeight: '180px',
            minHeight: '120px',
            //height: `${messageHeight}px`,
            //resize: 'none',
            ':focus': {
              outline: 'none'
            }
          }} onChange={onMessageChange} value={message || ''} />
          </div>
        </div>
        <div className="d-flex mb-3 justify-content-between">
          <select className="form-control" style={{
          height: '2rem',
          width: '136px'
        }} onChange={insertData} value={''}>
            <option>Insert Tag</option>
            {companyFields.map(field => <optgroup key={field.id} label={field.name}>
                {field.fields.map(subfield => <option key={subfield.id} value={getId(field.id, subfield.id)}>
                    {subfield.name}
                  </option>)}
              </optgroup>)}
          </select>
        </div>
      </>

      <ModalDialog open={showAttemptedAttachementModal} title="Attachments" submitText="Okay" allowBackdropClick={true} onClose={() => setShowAttemptedAttachementModal(false)} cancelBtn={false} onSubmit={() => setShowAttemptedAttachementModal(false)} level={10}>
        <p>
          The template you used has an attachment, but the Reply Reviews cannot send attachments. However you can still use the template in
          text only mode
        </p>
      </ModalDialog>
    </>;
};