import React, { Component } from 'react';
import { func, number, string, bool } from 'prop-types';
import { toOrdinal } from 'number-to-words';
import { Scoped, a, k } from 'kremling';
function getOrdinalForNumber(number) {
  const str = toOrdinal(number);
  return str.replace(number.toString(), '');
}
export class FancyInput extends Component {
  static propTypes = {
    onChange: func,
    value: number,
    className: string,
    ordinal: bool,
    leftMargin: bool
  };
  state = {
    isFocused: false
  };
  focus = () => this.setState({
    isFocused: true
  });
  blur = () => this.setState({
    isFocused: false
  });
  render() {
    const {
      isFocused
    } = this.state;
    const {
      onChange,
      value,
      className,
      ordinal,
      leftMargin
    } = this.props;
    return <Scoped css={css}>
        <div className={a('fancy-input').m('fancy-input--left-margin', leftMargin)}>
          <input onFocus={this.focus} onBlur={this.blur} onChange={onChange} value={value} className={a(`form-control inline ${className}`)} style={!isFocused ? {
          color: '#fff'
        } : {}} type="number" />
          {!isFocused && <span className="fancy-input__output">
              {value}
              {ordinal && getOrdinalForNumber(value)}
            </span>}
        </div>
      </Scoped>;
  }
}
const css = {
  styles: `[kremling="i47"] body,body[kremling="i47"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i47"] .fancy-input,[kremling="i47"].fancy-input {
  position: relative;
  display: inline-block;
  margin-right: 0.8rem;
}

[kremling="i47"] .fancy-input input,[kremling="i47"].fancy-input input {
  width: 6rem;
  padding-right: 0.2rem;
}

[kremling="i47"] .fancy-input--left-margin,[kremling="i47"].fancy-input--left-margin {
  margin-left: 0.8rem;
}

[kremling="i47"] .fancy-input__output,[kremling="i47"].fancy-input__output {
  position: absolute;
  left: 1rem;
  top: 0;
  height: 3rem;
  line-height: 3rem;
  pointer-events: none;
  padding-left: 0.1rem;
}`,
  id: 'i47',
  namespace: 'kremling'
};