import React from 'react';
function Icon(props) {
  if (props.size === 'lg') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill={props.fill || '#1D252D'} viewBox="0 -960 960 960">
        <path d="M333.03-297.64 480-444.62l146.97 146.98 35.39-35.39L515.38-480l146.98-146.97-35.39-35.39L480-515.38 333.03-662.36l-35.39 35.39L444.62-480 297.64-333.03zM480.07-100q-78.43 0-147.67-29.92t-120.89-81.54q-51.64-51.63-81.58-120.84Q100-401.51 100-479.93q0-78.84 29.92-148.21t81.54-120.68q51.63-51.31 120.84-81.25Q401.51-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21 81.25 120.63Q860-558.9 860-480.07q0 78.43-29.92 147.67t-81.21 120.89q-51.29 51.64-120.63 81.58Q558.9-100 480.07-100m-.07-50.26q137.79 0 233.77-96.18 95.97-96.18 95.97-233.56 0-137.79-95.97-233.77-95.98-95.97-233.77-95.97-137.38 0-233.56 95.97-96.18 95.98-96.18 233.77 0 137.38 96.18 233.56T480-150.26M480-480"></path>
      </svg>;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960">
      <path fill={props.fill || '#1D252D'} d="M336-293.847l144-144 144 144L666.153-336l-144-144 144-144L624-666.153l-144 144-144-144L293.847-624l144 144-144 144L336-293.847zm144.067 193.846q-78.836 0-148.204-29.92-69.369-29.92-120.682-81.21-51.314-51.291-81.247-120.629-29.933-69.337-29.933-148.173t29.92-148.204q29.92-69.369 81.21-120.682 51.291-51.314 120.629-81.247 69.337-29.933 148.173-29.933t148.204 29.92q69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173t-29.92 148.204q-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 29.933-148.173 29.933zM480-160q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93zm0-320z"></path>
    </svg>;
}
export default Icon;