import { Container, Graphics } from 'pixi.js/lib/core';
class Util {
  constructor() {
    this.shakingSprites = [];
  }
  update() {
    if (this.shakingSprites.length > 0) {
      for (let i = this.shakingSprites.length - 1; i >= 0; i--) {
        let shakingSprite = this.shakingSprites[i];
        if (shakingSprite.updateShake) shakingSprite.updateShake();
      }
    }
  }
  shake(sprite, magnitude = 16, angular = false) {
    let self = this;
    let counter = 1;
    let numberOfShakes = 30;
    let startX = sprite.x,
      startY = sprite.y,
      startAngle = sprite.rotation;
    let magnitudeUnit = magnitude / numberOfShakes;
    let randomInt = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };
    if (self.shakingSprites.indexOf(sprite) === -1) {
      self.shakingSprites.push(sprite);
      sprite.updateShake = () => {
        if (angular) {
          angularShake();
        } else {
          upAndDownShake();
        }
      };
    }
    function upAndDownShake() {
      if (counter < numberOfShakes) {
        sprite.x = startX;
        sprite.y = startY;
        magnitude -= magnitudeUnit;
        sprite.x += randomInt(-magnitude, magnitude);
        sprite.y += randomInt(-magnitude, magnitude);
        counter += 1;
      }
      if (counter >= numberOfShakes) {
        sprite.x = startX;
        sprite.y = startY;
        self.shakingSprites.splice(self.shakingSprites.indexOf(sprite), 1);
      }
    }
    let tiltAngle = 1;
    function angularShake() {
      if (counter < numberOfShakes) {
        sprite.rotation = startAngle;
        magnitude -= magnitudeUnit;
        sprite.rotation = magnitude * tiltAngle;
        counter += 1;
        tiltAngle *= -1;
      }
      if (counter >= numberOfShakes) {
        sprite.rotation = startAngle;
        self.shakingSprites.splice(self.shakingSprites.indexOf(sprite), 1);
      }
    }
  }
  pulse(sprite) {
    let self = this;
    let counter = 1;
    let numberOfShakes = 80;
    if (self.shakingSprites.indexOf(sprite) === -1) {
      self.shakingSprites.push(sprite);
      sprite.updateShake = () => {
        pulseIt();
      };
    }
    function pulseIt() {
      if (counter < numberOfShakes) {
        if (counter < 20 || counter > 40 && counter < 60) {
          //growing
          sprite.scale.x += 0.015;
          sprite.scale.y += 0.015;
        } else {
          sprite.scale.x -= 0.015;
          sprite.scale.y -= 0.015;
        }
        counter += 1;
      }
      if (counter >= numberOfShakes) {
        sprite.scale.x = 1;
        sprite.scale.y = 1;
        self.shakingSprites.splice(self.shakingSprites.indexOf(sprite), 1);
      }
    }
  }
  outline = sprite => {
    const container = new Container();
    const innerYellow = new Graphics();
    innerYellow.lineStyle(2, 0xc9eb34);
    innerYellow.drawRoundedRect(0, 0, 150, 150, 10);
    innerYellow.endFill();
    container.addChild(innerYellow);
    container.x = -15;
    container.y = -15;
    sprite.addChild(container);
  };
  grow(sprite) {
    let self = this;
    let counter = 1;
    let numberOfShakes = 20;
    if (self.shakingSprites.indexOf(sprite) === -1) {
      self.shakingSprites.push(sprite);
      sprite.updateShake = () => {
        growIt();
      };
    }
    function growIt() {
      if (counter < numberOfShakes) {
        if (sprite.scale.x < 1.3) {
          sprite.scale.x += 0.015;
        }
        if (sprite.scale.y < 1.3) {
          sprite.scale.y += 0.015;
        }
        counter += 1;
      }
      if (counter >= numberOfShakes) {
        self.shakingSprites.splice(self.shakingSprites.indexOf(sprite), 1);
      }
    }
  }
  ungrow(sprite) {
    let self = this;
    let counter = 1;
    let numberOfShakes = 20;
    if (self.shakingSprites.indexOf(sprite) === -1) {
      self.shakingSprites.push(sprite);
      sprite.updateShake = () => {
        ungrowIt();
      };
    }
    function ungrowIt() {
      if (counter < numberOfShakes) {
        if (sprite.scale.x > 1) {
          sprite.scale.x -= 0.015;
        }
        if (sprite.scale.y > 1) {
          sprite.scale.y -= 0.015;
        }
        counter += 1;
      }
      if (counter >= numberOfShakes) {
        sprite.scale.x = 1;
        sprite.scale.y = 1;
        self.shakingSprites.splice(self.shakingSprites.indexOf(sprite), 1);
      }
    }
  }
}
export const pixiUtils = new Util();