import { Container, Graphics, Sprite } from 'pixi.js';
import warningImg from '../assets/warning.png';
export class NodeValid {
  constructor() {
    this.container = this.build();
  }
  build = () => {
    const container = new Container();
    const size = 30;
    const radius = size / 2;
    const innerYellow = new Graphics();
    innerYellow.lineStyle(1, 0xffaf7d);
    innerYellow.beginFill(0xffffff);
    innerYellow.drawCircle(radius, radius, radius);
    innerYellow.endFill();
    this.icon = new Sprite.from(warningImg);
    this.icon.scale.x = 0.45;
    this.icon.scale.y = 0.45;
    this.icon.x = 6;
    this.icon.y = 5.7;
    //this.icon.alpha = 0.7;
    this.icon.tint = 0xffaf7d;
    container.addChild(innerYellow);
    container.addChild(this.icon);
    container.alpha = 0;
    return container;
  };
}