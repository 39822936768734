import React, { Component } from 'react';
import { func, object, shape, number } from 'prop-types';
import { isInteger } from 'lodash';
import { Scoped, k } from 'kremling';
export class ActionAB extends Component {
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      group_a: number
    })
  };
  update = (isA, val) => {
    const parsedVal = parseInt(val);
    let percentage = 0;
    if (parsedVal > 100) percentage = 100;
    if (percentage === 0 && parsedVal && parsedVal >= 0) percentage = parsedVal;
    this.props.update({
      group_a: isA ? percentage : 100 - percentage
    });
  };
  render() {
    const {
      group_a
    } = this.props.data;
    return isInteger(group_a) ? <Scoped css={css}>
        <div>
          <div className="ab-close-group">
            <div className="form-group">
              <label>Group A</label>
              <div className="ab-close-group__inner">
                <input type="text" className="form-control" value={group_a} onChange={e => this.update(true, e.target.value)} />
                %
              </div>
            </div>
            <div className="form-group">
              <label>Group B</label>
              <div className="ab-close-group__inner">
                <input type="text" className="form-control" value={100 - group_a} onChange={e => this.update(false, e.target.value)} />
                %
              </div>
            </div>
          </div>
        </div>
      </Scoped> : null;
  }
}
const css = {
  styles: `[kremling="i27"] body,body[kremling="i27"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i27"] .ab-close-group,[kremling="i27"].ab-close-group {
  display: flex;
  width: 200px;
  margin: 0 auto;
}

[kremling="i27"] .ab-close-group>div,[kremling="i27"].ab-close-group>div {
  margin: 14px;
}

[kremling="i27"] .ab-close-group__inner,[kremling="i27"].ab-close-group__inner {
  display: flex;
  align-items: center;
}

[kremling="i27"] .ab-close-group__inner input,[kremling="i27"].ab-close-group__inner input {
  margin-right: 8px;
  text-align: right;
}`,
  id: 'i27',
  namespace: 'kremling'
};